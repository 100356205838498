export const SET_INSTALLATION_FILTERS = "SET_INSTALLATION_FILTERS";
export const RESET_INSTALLATION_FILTERS = "RESET_INSTALLATION_FILTERS";
export const SET_INSTALLATION_SEARCH_TOOLS_OPENED = "SET_INSTALLATION_SEARCH_TOOLS_OPENED";
export const SET_INSTALLATION_LOADING = "SET_INSTALLATION_LOADING";

export const setInstallationFilters = filters => {
    return {
        type:    SET_INSTALLATION_FILTERS,
        filters: filters,
    };
};

export const resetInstallationFilters = () => {
    return {
        type: RESET_INSTALLATION_FILTERS,
    };
};

export const setInstallationSearchToolsOpened = val => {
    return {
        type:  SET_INSTALLATION_SEARCH_TOOLS_OPENED,
        value: val,
    };
};

export const setInstallationLoading = val => ({
    type:  SET_INSTALLATION_LOADING,
    value: val,
});
